import { formatCurrency } from '../../../utils/valueFormatters';

import {
  Document,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
  Font,
} from '@react-pdf/renderer';

Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf',
});

Font.register({
  family: 'Roboto-bold',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
});

export const OfferPDF = (props) => {
  const { offer, user, calculateNetSum, createdAt } = props;

  const styles = StyleSheet.create({
    table: {
      display: 'flex',
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 1,
    },
    tableRow: {
      margin: 'auto',
      flexDirection: 'row',
    },
    tableCol: {
      width: '15%',
      borderStyle: 'solid',
      borderWidth: 0,
      borderLeftWidth: 0,
      borderTopWidth: 1,
    },
    tableColName: {
      width: '40%',
      borderStyle: 'solid',
      borderWidth: 0,
      borderLeftWidth: 0,
      borderTopWidth: 1,
    },
    tableCell: {
      margin: 'auto',
      marginTop: 5,
      fontSize: 12,
    },
    page: {
      fontFamily: 'Roboto',
      padding: 20,
      fontSize: 12,
    },
    tableHeader: {
      color: 'white',
      backgroundColor: 'black',
      fontWeight: 'bold',
      margin: 'auto',
      flexDirection: 'row',
    },
    header: {
      flexDirection: 'column',
      marginBottom: 20,
    },
    logo: {
      width: '50%',
      alignContent: 'center',
      marginBottom: 20,
    },
    contactInfo: {
      width: '100%',
    },
    companyName: {
      fontSize: 12,
      fontWeight: 'bold',
      fontFamily: 'Roboto-bold',
    },
    address: {
      fontSize: 12,
    },
    phone: {
      fontSize: 12,
    },
    content: {
      marginTop: 20,
    },
    title: {
      fontSize: 24,
      fontWeight: 'bold',
      textAlign: 'center',
    },
    total: {
      textAlign: 'right',
      marginTop: 20,
    },
    date: {
      textAlign: 'right',
    },
  });

  return (
    <Document language="hu">
      <Page style={styles.page}>
        <View style={styles.header} fixed>
          <View
            style={{
              width: '100%',
              alignItems: 'center',
              marginBottom: '20px ',
            }}
          >
            <Image src="logo_nobg_full.svg" style={{ width: '50%' }} />
          </View>
          <View style={{ width: '100%' }}>
            <Text style={{ textAlign: 'center' }}>
              Zárbolt & Kulcsmásoló - Letis Magyarország Kft.
            </Text>
            <View style={{ flexDirection: 'row', fontSize: 10 }}>
              <View style={{ width: '30%' }}>
                <Text>0-24 ZÁRSZERVIZ:</Text>
              </View>
              <View style={{ width: '20%' }}>
                <Text>06-30-929-7006</Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text>Székhely/Postacím:</Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text>1037 Budapest, Erdőalja út 46.</Text>
              </View>
            </View>
            <View style={{ flexDirection: 'row', fontSize: 10 }}>
              <View style={{ width: '30%' }}>
                <Text>ZÁRBOLT & KULCSMÁSOLÓ:</Text>
              </View>
              <View style={{ width: '20%' }}>
                <Text>06-30-990-8102</Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text>Bemutatóterem/Üzlet:</Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text>1149 Budapest, Várna utca 5/b.</Text>
              </View>
            </View>
            <View style={{ flexDirection: 'row', fontSize: 10 }}>
              <View style={{ width: '30%' }}>
                <Text>TŰZVÉDELEM/LAKATOSMŰHELY:</Text>
              </View>
              <View style={{ width: '20%' }}>
                <Text>06-70-378-5829</Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text>Adószám:</Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text>24151131-2-41</Text>
              </View>
            </View>
            <View style={{ flexDirection: 'row', fontSize: 10 }}>
              <View style={{ width: '30%', borderBottom: '1px solid black' }}>
                <Text>E-mail:</Text>
              </View>
              <View style={{ width: '20%', borderBottom: '1px solid black' }}>
                <Text>iroda@zarszerviz.hu</Text>
              </View>
              <View style={{ width: '25%', borderBottom: '1px solid black' }}>
                <Text>Cégjegyzék szám:</Text>
              </View>
              <View style={{ width: '25%', borderBottom: '1px solid black' }}>
                <Text>01-09-992862</Text>
              </View>
            </View>
          </View>
        </View>
        <Text style={styles.title}>ÁRAJÁNLAT</Text>
        <View style={{ flexDirection: 'row', margin: '20px' }}>
          <View style={{ width: '50%', textAlign: 'left' }}>
            <Text style={styles.companyName}>Vevő:</Text>
            <Text style={styles.address}>{offer?.clientName ?? ''}</Text>
            <Text style={styles.phone}>{offer?.workAddress ?? ''}</Text>
            <Text>{offer?.clientPhone}</Text>
            <Text>{offer?.clientEmail}</Text>
          </View>
          <View style={{ width: '50%', textAlign: 'right' }}>
            <Text style={styles.companyName}>Árajánlatot készítette:</Text>
            <Text style={styles.companyName}>{user?.fullName}</Text>
            <Text style={styles.address}>{user?.personalEmail}</Text>
            <Text style={styles.phone}>{user?.phoneNumber}</Text>
          </View>
        </View>
        <Text
          style={{ textAlign: 'center', width: '100%', marginBottom: '20px' }}
        >
          {offer?.task}
        </Text>
        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <View style={styles.tableColName}>
              <Text style={styles.tableCell}> Termék</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Darabszám</Text>
            </View>

            <View style={styles.tableCol}>
              <Text style={styles.tableCell}> Ár&nbsp;(nettó)</Text>
            </View>

            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Ár&nbsp;(bruttó)</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Összesen&nbsp;</Text>
            </View>
          </View>
          {offer?.selectedItems?.map((item, index) => {
            return (
              <View key={index} style={styles.tableRow}>
                <View style={styles.tableColName}>
                  <Text style={styles.tableCell}>
                    {item.itemAlternateName !== '' &&
                    item.itemAlternateName !== null
                      ? item.itemAlternateName
                      : item.itemName}
                  </Text>
                  <Text style={styles.tableCell}>{item.comment}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{item.amount}</Text>
                </View>

                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {formatCurrency(
                      (item.sellPriceNet * (1 - item.discount / 100))?.toFixed(
                        0
                      )
                    )}
                  </Text>
                </View>

                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {formatCurrency(
                      (
                        item.sellPriceGross *
                        (1 - item.discount / 100)
                      )?.toFixed(0)
                    )}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {formatCurrency(
                      (
                        item.sellPriceGross *
                        (1 - item.discount / 100) *
                        item.amount
                      )?.toFixed(0)
                    )}{' '}
                  </Text>
                </View>
              </View>
            );
          })}
          {offer?.selectedServices?.map((item, index) => {
            return (
              <View key={index} style={styles.tableRow}>
                <View style={styles.tableColName}>
                  <Text style={styles.tableCell}>{item.name}</Text>
                  <Text style={styles.tableCell}>{item.comment}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{item.amount}</Text>
                </View>

                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {formatCurrency(
                      (
                        item.servicePriceNet *
                        (1 - item.discount / 100)
                      )?.toFixed(0)
                    )}
                  </Text>
                </View>

                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {formatCurrency(
                      (
                        item.servicePriceGross *
                        (1 - item.discount / 100)
                      )?.toFixed(0)
                    )}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {formatCurrency(
                      (
                        item.servicePriceGross *
                        (1 - item.discount / 100) *
                        item.amount
                      )?.toFixed(0)
                    )}
                  </Text>
                </View>
              </View>
            );
          })}
          {offer?.itemPackages?.map((item, index) => {
            return (
              <View key={index} style={styles.tableRow}>
                <View style={styles.tableColName}>
                  <Text style={styles.tableCell}>{item.name}</Text>
                  <Text style={styles.tableCell}>{item.comment}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{item.amount}</Text>
                </View>

                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {formatCurrency(
                      (item.sellPriceNet * (1 - item.discount / 100))?.toFixed(
                        0
                      )
                    )}
                  </Text>
                </View>

                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {formatCurrency(
                      (
                        item.sellPriceGross *
                        (1 - item.discount / 100)
                      )?.toFixed(0)
                    )}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {formatCurrency(
                      (
                        item.sellPriceGross *
                        (1 - item.discount / 100) *
                        item.amount
                      )?.toFixed(0)
                    )}{' '}
                  </Text>
                </View>
              </View>
            );
          })}
        </View>
        <View
          style={{
            marginTop: '20px',
            position: 'absolute',
            bottom: 30,
            right: 10,
          }}
        >
          <View style={{ flexDirection: 'row', width: '100%' }}>
            <View style={{ width: '50%' }}>
              <Text style={{ textAlign: 'left', fontSize: 12 }}>
                Fizetendő(Nettó):
              </Text>
            </View>
            <View style={{ width: '50%' }}>
              <Text style={{ textAlign: 'right', fontSize: 12 }}>
                {formatCurrency(calculateNetSum()?.toFixed(0))}{' '}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row', width: '100%' }}>
            <View style={{ width: '50%' }}>
              <Text style={{ textAlign: 'left', fontSize: 12 }}>
                Fizetendő(ÁFA):
              </Text>
            </View>
            <View style={{ width: '50%' }}>
              <Text style={{ textAlign: 'right', fontSize: 12 }}>
                {formatCurrency((offer?.price - calculateNetSum())?.toFixed(0))}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row', width: '100%' }}>
            <View style={{ width: '50%' }}>
              <Text style={{ textAlign: 'left', fontSize: 12 }}>
                Fizetendő(Bruttó):
              </Text>
            </View>
            <View style={{ width: '50%' }}>
              <Text style={{ textAlign: 'right', fontSize: 12 }}>
                {formatCurrency(offer?.price?.toFixed(0))}
              </Text>
            </View>
          </View>
        </View>
      </Page>
      <Page style={styles.page}>
        <View style={{ width: '100%' }}>
          <Text style={{ textAlign: 'center' }}>
            Zárbolt & Kulcsmásoló - Letis Magyarország Kft.
          </Text>
          <View style={{ flexDirection: 'row', fontSize: 10 }}>
            <View style={{ width: '30%' }}>
              <Text>0-24 ZÁRSZERVIZ:</Text>
            </View>
            <View style={{ width: '20%' }}>
              <Text>06-30-929-7006</Text>
            </View>
            <View style={{ width: '25%' }}>
              <Text>Székhely/Postacím:</Text>
            </View>
            <View style={{ width: '25%' }}>
              <Text>1037 Budapest, Erdőalja út 46.</Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row', fontSize: 10 }}>
            <View style={{ width: '30%' }}>
              <Text>ZÁRBOLT & KULCSMÁSOLÓ:</Text>
            </View>
            <View style={{ width: '20%' }}>
              <Text>06-30-990-8102</Text>
            </View>
            <View style={{ width: '25%' }}>
              <Text>Bemutatóterem/Üzlet:</Text>
            </View>
            <View style={{ width: '25%' }}>
              <Text>1149 Budapest, Várna utca 5/b.</Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row', fontSize: 10 }}>
            <View style={{ width: '30%' }}>
              <Text>TŰZVÉDELEM/LAKATOSMŰHELY:</Text>
            </View>
            <View style={{ width: '20%' }}>
              <Text>06-70-378-5829</Text>
            </View>
            <View style={{ width: '25%' }}>
              <Text>Adószám:</Text>
            </View>
            <View style={{ width: '25%' }}>
              <Text>24151131-2-41</Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row', fontSize: 10 }}>
            <View style={{ width: '30%', borderBottom: '1px solid black' }}>
              <Text>E-mail:</Text>
            </View>
            <View style={{ width: '20%', borderBottom: '1px solid black' }}>
              <Text>iroda@zarszerviz.hu</Text>
            </View>
            <View style={{ width: '25%', borderBottom: '1px solid black' }}>
              <Text>Cégjegyzék szám:</Text>
            </View>
            <View style={{ width: '25%', borderBottom: '1px solid black' }}>
              <Text>01-09-992862</Text>
            </View>
          </View>
        </View>
        <View
          style={{ flexDirection: 'row', width: '100%', marginTop: '20px' }}
        >
          <View style={{ width: '20%' }}>
            <Text style={{ textAlign: 'left' }}>Garancia:</Text>
          </View>
          <View style={{ width: '80%' }}>
            <Text style={{ textAlign: 'left', fontSize: 12 }}>
              {offer?.guarantee}
            </Text>
          </View>
        </View>
        <View
          style={{ flexDirection: 'row', width: '100%', marginTop: '20px' }}
        >
          <View style={{ width: '20%' }}>
            <Text style={{ textAlign: 'left' }}>Határidő:</Text>
          </View>
          <View style={{ width: '80%' }}>
            <Text style={{ textAlign: 'left', fontSize: 12 }}>
              {offer?.deadLine}
            </Text>
          </View>
        </View>
        <View
          style={{ flexDirection: 'row', width: '100%', marginTop: '20px' }}
        >
          <View style={{ width: '20%' }}>
            <Text style={{ textAlign: 'left' }}>Feltételek:</Text>
          </View>
          <View style={{ width: '80%' }}>
            <Text style={{ textAlign: 'left', fontSize: 12 }}>
              {offer?.conditions}
            </Text>
          </View>
        </View>
        <View
          style={{ flexDirection: 'row', width: '100%', marginTop: '20px' }}
        >
          <View style={{ width: '20%' }}>
            <Text style={{ textAlign: 'left' }}>Megjegyzés:</Text>
          </View>
          <View style={{ width: '80%' }}>
            <Text style={{ textAlign: 'left', fontSize: 12 }}>
              {offer?.remark}
            </Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            position: 'absolute',
            bottom: 30,
            right: 10,
          }}
          fixed
        >
          <View style={{ width: '50%' }}>
            <Text style={{ textAlign: 'left' }}>
              Tisztelettel, {user?.fullName}
            </Text>
          </View>
          <View style={{ width: '50%' }}>
            <Text style={{ textAlign: 'right' }}>
              Budapest, {createdAt ?? ''}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
