import { useMsal } from '@azure/msal-react';
import { Login, Logout } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import { red } from '@mui/material/colors';
import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useCheckPermission from '../hooks/useCheckPermission';
import useClosingSheetCreate from '../hooks/useClosingSheetCreate';
import useSession from '../hooks/useSession';
import closingSheetService from '../services/erp/closingSheetService';
import systemConfigurationService from '../services/systemConfigurationService';
import warehouseService from '../services/wms/warehouseService';
import {
  setSelectedWarehouseId,
  setWarehouses,
} from '../stateManagement/actions/userWarehouseActions';
import { translateRoleName } from '../utils/nameFormatters';
import AcquisitionInProgress from './AcquisitionInProgress';
import NotificationsBell from './NotificationsBell';
import OfferInProgress from './OfferInProgress';
import WorkItemInProgress from './WorkItemInProgress';
import moment from 'moment';
import { isSameDay } from 'date-fns';
import useSignalREffect from '../hooks/useSignalREffect';
import workItemService from '../services/erp/workItemService';
import { WorkItemStatus } from '../pages/erp/workItems/WorkItemForm';
import appService from '../services/appService';
import taskService from '../services/erp/taskService';
import { useSnackbar } from 'notistack';
import { loginRequest } from '../authConfig';
import clientService from '../services/crm/clientService';
import { ClientTypes } from '../types/ClientTypes';
import {
  setDistributors,
  setSelectedDistributor,
} from '../stateManagement/actions/userDistributorActions';

interface HeaderProps {
  onDrawerToggle: () => void;
}

export default function Header(props: HeaderProps) {
  const { onDrawerToggle } = props;
  const { instance, accounts } = useMsal();
  const user = useSelector((state: any) => state.user.userInfo);
  const userWarehouse = useSelector((state: any) => state.userWarehouse);
  const userDistributor = useSelector((state: any) => state.userDistributor);
  const connection = useSignalREffect('taskHub');

  const [dialogOpen, setDialogOpen] = useState({
    open: false,
    createNewSheet: false,
  });
  const [config, setConfig] = useState({ key: '', value: '' });
  const { SessionTimer } = useSession(parseInt(config?.value));
  const [todaysClosingSheet, setTodaysClosingSheet] = useState<any>({});
  const [pendingTasks, setPendingTasks] = React.useState<any[]>([]);
  const navigate = useNavigate();
  const hasClosingSheet = useSelector(
    (state: any) => state.app.hasClosingSheet
  );
  const { createClosingSheet } = useClosingSheetCreate();
  const { checkPermission } = useCheckPermission();
  const { enqueueSnackbar } = useSnackbar();

  const { acquisitionInProgress } = useSelector<any>(
    (state) => state.acquisition
  ) as any;
  const { offerInProgress } = useSelector<any>((state) => state.offer) as any;
  const { workItemInProgress } = useSelector<any>(
    (state) => state.workItem
  ) as any;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();

  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);

  const isMechanic = user?.authorityInfo.roles.some(
    (x) => x.name === 'Mechanic'
  );
  useEffect(() => {
    warehouseService.listUser(user?.userId).then((response) => {
      if (response.canceled) return;
      if (!response.hasError) {
        if (response.records.length === 0) return;

        dispatch(setWarehouses(response.records));
        dispatch(setSelectedWarehouseId(response.records[0].id));
      }
    });
  }, [user]);

  useEffect(() => {
    clientService
      .list(true, null, null, ClientTypes.Distributor)
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) {
          if (response.records.length === 0) return;

          dispatch(setDistributors(response.records));
          dispatch(setSelectedDistributor(response.records[0].id));
        }
      });
  }, [user]);

  useEffect(() => {
    if (checkPermission(['TaskView'])) {
      taskService.listmy().then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setPendingTasks(response.records.filter((row) => !row.isCompleted));
        }
      });
    }
  }, [user]);

  const handleGotNewTask = useCallback(
    (
      employeeName: string,
      employeeId: number,
      date: Date,
      description: string
    ) => {
      if (user.userId === employeeId) {
        taskService.listmy().then((response) => {
          if (response.canceled) return;
          if (response.hasError) {
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
          } else {
            setPendingTasks(response.records.filter((row) => !row.isCompleted));
          }
        });
        enqueueSnackbar(
          `${description} Dátum: ${new Date(date).toLocaleDateString()}`,
          {
            variant: 'info',
          }
        );
      }
    },
    [user]
  );

  useEffect(() => {
    if (!connection) return;

    var callback = handleGotNewTask;
    connection.on('GotNewTask', callback);

    return () => {
      connection.off('GotNewTask', callback);
    };
  }, [connection, handleGotNewTask]);

  const handleLogoutDialogClose = () => {
    setOpenLogoutDialog(false);
  };
  const generateColor = (name) => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const c = (hash & 0x00f555ff).toString(16).toUpperCase();
    return '#' + '00000'.substring(0, 6 - c.length) + c;
  };

  React.useEffect(() => {
    systemConfigurationService
      .getByKey('account.sessionTimeout')
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setConfig(response.result);
      });
  }, [user]);

  useEffect(() => {
    if (isMechanic && checkPermission(['ClosingSheetView'])) {
      closingSheetService.getToday().then((response) => {
        if (response.canceled) return;
        if (!response.hasError) {
          dispatch({ type: 'SET_HAS_CLOSING_SHEET', payload: true });
          setTodaysClosingSheet(response.result);
          if (!isSameDay(new Date(), new Date(response.result.date))) {
            setDialogOpen({ open: true, createNewSheet: false });
          }
        } else {
          dispatch({ type: 'SET_HAS_CLOSING_SHEET', payload: false });
          setDialogOpen({ open: true, createNewSheet: true });
        }
      });
    }
  }, [hasClosingSheet, isMechanic]);

  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Grid
              sx={{ display: { lg: 'none', xs: 'block', color: 'white' } }}
              item
              xs={0.5}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            {(workItemInProgress ||
              acquisitionInProgress ||
              offerInProgress) && (
              <Grid item container xs={2} md={1.5} lg={2.5}>
                {acquisitionInProgress && (
                  <Grid
                    item
                    xs={workItemInProgress || offerInProgress ? 4 : 12}
                    textAlign={'left'}
                  >
                    <AcquisitionInProgress />
                  </Grid>
                )}
                {offerInProgress && (
                  <Grid
                    item
                    xs={workItemInProgress || acquisitionInProgress ? 4 : 12}
                    textAlign={'left'}
                  >
                    <OfferInProgress />
                  </Grid>
                )}
                {workItemInProgress && (
                  <Grid
                    item
                    xs={acquisitionInProgress || offerInProgress ? 4 : 12}
                    textAlign={'left'}
                  >
                    <WorkItemInProgress />
                  </Grid>
                )}
              </Grid>
            )}
            <Grid item xs={2} md={3} lg={3} container>
              <SessionTimer />
            </Grid>
            <Grid
              container
              item
              xs={
                workItemInProgress || acquisitionInProgress || offerInProgress
                  ? 7.5
                  : 9.5
              }
              md={
                workItemInProgress || acquisitionInProgress || offerInProgress
                  ? 7
                  : 8.5
              }
              lg={
                workItemInProgress || acquisitionInProgress || offerInProgress
                  ? 6.5
                  : 9
              }
              justifyContent="flex-end"
              sx={{ width: '100%' }}
            >
              <Grid
                item
                alignSelf={'center'}
                textAlign={'right'}
                sx={{ pr: { xs: 2, md: 3 } }}
                xs={12}
              >
                {!hasClosingSheet && isMechanic && (
                  <>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => {
                        createClosingSheet(() =>
                          dispatch({
                            type: 'SET_HAS_CLOSING_SHEET',
                            payload: true,
                          })
                        );
                      }}
                    >
                      Munkanap kezdés
                    </Button>
                  </>
                )}
                {isMechanic && hasClosingSheet && (
                  <>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => {
                        navigate(
                          `/erp/closingSheets/edit/${todaysClosingSheet?.id}`
                        );
                      }}
                    >
                      Munkanap zárása
                    </Button>
                  </>
                )}
                <div style={{ color: 'white', display: 'inline-block' }}>
                  <NotificationsBell pendingTasks={pendingTasks} />
                </div>
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{
                      sx: { ml: 2 },
                      md: { ml: 0 },
                    }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                  >
                    {user?.imageURL ? (
                      <img
                        width={40}
                        height={40}
                        src={user?.imageURL}
                        alt="nincs"
                      />
                    ) : (
                      <Avatar
                        sx={{
                          width: 40,
                          height: 40,
                          background: generateColor(user?.fullName) ?? '#000',
                        }}
                      >
                        {user?.fullName[0]}
                        {user?.fullName[0].includes(' ') &&
                          user?.fullName.split(' ')[1][0]}
                      </Avatar>
                    )}
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 64,
                        height: 64,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <MenuItem onClick={handleClose}>
                    <Box sx={{ p: 2, textAlign: 'center', width: '100%' }}>
                      <Grid
                        container
                        spacing={2}
                        sx={{
                          alignItems: 'center',
                          justifyContent: 'center',
                          textAlign: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <Grid item xs={12}>
                          {user?.imageURL ? (
                            <img
                              width={100}
                              height={100}
                              src={user?.imageURL}
                              alt="nincs"
                            />
                          ) : (
                            <Avatar
                              sx={{
                                width: 32,
                                height: 32,
                                background:
                                  generateColor(user?.fullName) ?? '#000',
                              }}
                            >
                              {user?.fullName[0]}
                              {user?.fullName[0].includes(' ') &&
                                user?.fullName.split(' ')[1][0]}
                            </Avatar>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h5">{user?.fullName}</Typography>
                          <Typography variant="subtitle1">
                            {user?.email}
                          </Typography>
                          <Typography variant="body2">
                            {user?.authorityInfo.roles.map((x, index) => {
                              const translatedRoleName = translateRoleName(
                                x.name
                              );
                              return (
                                <React.Fragment key={x.name}>
                                  {index > 0 && index % 3 === 0 && <br />}{' '}
                                  {index > 0 && ', '} {translatedRoleName}
                                </React.Fragment>
                              );
                            })}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </MenuItem>
                  <Divider> Használt raktár</Divider>
                  <MenuItem onClick={(event) => event.stopPropagation()}>
                    <Grid container spacing={2} textAlign={'center'}>
                      <Grid item xs={12}>
                        <Autocomplete
                          id="warehouse-select"
                          value={userWarehouse?.selectedWarehouseId}
                          disabled={userWarehouse?.warehouses?.length === 1}
                          getOptionLabel={(option) =>
                            userWarehouse?.warehouses?.find(
                              (x) => x.id === option
                            )?.name
                          }
                          options={userWarehouse.warehouses?.map(
                            (option) => option.id
                          )}
                          onChange={(event, newValue) => {
                            dispatch(setSelectedWarehouseId(newValue));
                          }}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              {...params}
                              label="Raktár választás"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </MenuItem>
                  <Divider>Számlakibocsátó</Divider>
                  <MenuItem onClick={(event) => event.stopPropagation()}>
                    <Grid container spacing={2} textAlign={'center'}>
                      <Grid item xs={12}>
                        <Autocomplete
                          value={userDistributor?.selectedDistributor}
                          disabled={userDistributor?.distributors?.length === 1}
                          getOptionLabel={(option) =>
                            userDistributor?.distributors?.find(
                              (x) => x.id === option
                            )?.companyName
                          }
                          options={userDistributor.distributors?.map(
                            (option) => option.id
                          )}
                          onChange={(event, newValue) => {
                            dispatch(setSelectedDistributor(newValue));
                          }}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              {...params}
                              label="Számlakibocsátó választás"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </MenuItem>
                  <Divider title="Fiókok">Fiók váltás</Divider>
                  {accounts.map((account) => (
                    <MenuItem
                      onClick={() => instance.setActiveAccount(account)}
                      key={account.username}
                    >
                      <Avatar
                        sx={{
                          width: '32px !important',
                          height: '32px !important',
                          background: generateColor(account?.name) ?? '#000',
                        }}
                      >
                        {account?.name[0]}
                        {account?.name?.includes(' ') &&
                          account?.name.split(' ')[1][0]}
                      </Avatar>
                      {account.name}
                      <br />
                      {account.username}
                    </MenuItem>
                  ))}
                  <Divider />
                  <MenuItem onClick={() => setOpenLogoutDialog(true)}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Kijelentkezés
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
            <Grid item>
              <Dialog
                open={openLogoutDialog}
                onClose={handleLogoutDialogClose}
                maxWidth="xs"
                fullWidth
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <DialogTitle
                    sx={{
                      backgroundColor: red[500],
                      color: 'white',
                      width: '100%',
                      textAlign: 'center',
                    }}
                  >
                    Kilépés
                  </DialogTitle>

                  <DialogContent>
                    <DialogContentText>
                      Biztosan ki szeretne lépni?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => instance.logoutRedirect()}
                      variant="contained"
                      sx={{ backgroundColor: red[500], color: 'white' }}
                    >
                      Igen
                    </Button>
                    <Button
                      onClick={handleLogoutDialogClose}
                      color="secondary"
                      variant="outlined"
                    >
                      Nem
                    </Button>
                  </DialogActions>
                </Box>
              </Dialog>
            </Grid>
            <Dialog
              open={dialogOpen.open}
              onClose={() => {
                setDialogOpen({ open: false, createNewSheet: false });
              }}
              maxWidth="lg"
            >
              <Grid container textAlign="center" spacing={2} p={2}>
                <Grid item xs={12}>
                  {dialogOpen.createNewSheet
                    ? 'Még nem kezdted el a munkanapot!'
                    : 'Még nem zártad le a tegnapi munkanapot!'}
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color={dialogOpen.createNewSheet ? 'success' : 'error'}
                    onClick={() => {
                      if (dialogOpen.createNewSheet) {
                        createClosingSheet(() =>
                          dispatch({
                            type: 'SET_HAS_CLOSING_SHEET',
                            payload: true,
                          })
                        );
                        setDialogOpen({
                          open: false,
                          createNewSheet: false,
                        });
                      } else {
                        navigate(
                          `/erp/closingSheets/edit/${todaysClosingSheet?.id}`
                        );
                        setDialogOpen({
                          open: false,
                          createNewSheet: false,
                        });
                      }
                    }}
                  >
                    {dialogOpen.createNewSheet
                      ? 'Munkanap kezdés'
                      : 'Munkanap zárása'}
                  </Button>
                </Grid>
              </Grid>
            </Dialog>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
