import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React, { useEffect } from 'react';
import { formatCurrency } from '../../../../../utils/valueFormatters';
import ReasonDialog from './ReasonDialog';
import InvoiceInfoDialog from '../../../../sales/InvoiceInfoDialog';
import {
  setPaymentType,
  setCompanyId,
} from '../../../../../stateManagement/actions/salesActions';
import { useDispatch, useSelector } from 'react-redux';
import { ClientTypes } from '../../../../../types/ClientTypes';
import { read } from 'fs';
import { WorkItemStatus } from '../../WorkItemForm';
import orderService from '../../../../../services/sales/orderService';
import { usePDF, PDFViewer } from '@react-pdf/renderer';
import { OrderPDF } from '../../../../sales/orders/OrderPDF';
import { WorkItemPDF } from '../../WorkItemPDF';

const CompleteWorkItem: React.FC = (props: any) => {
  const {
    values,
    readonly,
    setIsSubmitting,
    setFieldValue,
    isSubmitting,
    clients,
    onClientAdded,
    onCompleteWithInvoice,
    onCompleteWithoutInvoice,
    invoiceNumberPrefixes,
    addInvoice,
    documentBlocks,
    taskTypes,
    services,
  } = props;
  const paymentTypes = ['Készpénz', 'Bankkártya', 'Átutalás', 'Utánvét'];
  const { companyId, paymentType } = useSelector((state: any) => state.sales);
  const [invoiceInfoDialogOpen, setInvoiceDialogOpen] = React.useState(false);
  const [hasNoInvoice, setHasNoInvoice] = React.useState(true);
  const user = useSelector((state: any) => state.user.userInfo);
  const createdAt = new Date(values?.createdAt).toLocaleDateString('hu-HU');

  const [inoviceAfterwardsDialog, setInvoiceAfterwardsDialog] =
    React.useState(false);
  const dispatch = useDispatch();

  const netPrice =
    values.selectedItems?.reduce((a, x) => a + x.sellPriceNet * x.amount, 0) +
    values.itemPackages?.reduce((a, x) => a + x.sellPriceNet * x.amount, 0) +
    values.selectedServices?.reduce(
      (a, x) => a + x.servicePriceNet * x.amount,
      0
    );
  const grossPrice =
    values.selectedItems?.reduce((a, x) => a + x.sellPriceGross * x.amount, 0) +
    values.itemPackages?.reduce((a, x) => a + x.sellPriceGross * x.amount, 0) +
    values.selectedServices?.reduce(
      (a, x) => a + x.servicePriceGross * x.amount,
      0
    );

  const discount =
    values.selectedItems?.reduce(
      (a, x) => a + x.sellPriceGross * x.amount * (x.discount / 100),
      0
    ) +
    values.itemPackages?.reduce(
      (a, x) => a + x.sellPriceGross * x.amount * (x.discount / 100),
      0
    ) +
    values.selectedServices?.reduce(
      (a, x) => a + x.servicePriceGross * x.amount * (x.discount / 100),
      0
    );
  const discountNet =
    values.selectedItems?.reduce(
      (a, x) => a + x.sellPriceNet * x.amount * (x.discount / 100),
      0
    ) +
    values.itemPackages?.reduce(
      (a, x) => a + x.sellPriceNet * x.amount * (x.discount / 100),
      0
    ) +
    values.selectedServices?.reduce(
      (a, x) => a + x.servicePriceNet * x.amount * (x.discount / 100),
      0
    );

  const payPrice = grossPrice - discount;

  useEffect(() => {
    if (values.orderId > 0) {
      orderService.get(values.orderId).then((res) => {
        setHasNoInvoice(
          res.result.invoiceNumber === null || res.result.invoiceNumber === ''
        );
      });
    }
  }, [values.orderId]);

  const calculatefinalPrice = (isGross: boolean) => {
    return isGross ? grossPrice - discount : netPrice - discountNet;
  };

  const [orderInstance, orderUpdate] = usePDF({
    document: (
      <OrderPDF
        order={{
          items: values.selectedItems,
          itemPackages: values.itemPackages,
          discount: 0,
          paymentType,
          companyId,
          clientId: values.clientId,
          services: values.selectedServices.map(({ amount, ...rest }) => ({
            quantity: amount,
            ...rest,
          })),
        }}
        client={clients.find((x) => x.id === values.clientId)}
        calculatefinalPrice={calculatefinalPrice}
      />
    ),
  });

  useEffect(() => {
    orderUpdate(
      <OrderPDF
        order={{
          items: values.selectedItems,
          itemPackages: values.itemPackages,
          discount: 0,
          paymentType,
          companyId,
          clientId: values.clientId,
          services: values.selectedServices.map(({ amount, ...rest }) => ({
            quantity: amount,
            ...rest,
          })),
        }}
        client={clients.find((x) => x.id === values.clientId)}
        calculatefinalPrice={calculatefinalPrice}
      />
    );
  }, [values, clients]);

  const [workItemInstance, workItemUpdate] = usePDF({
    document: (
      <WorkItemPDF
        workItem={values}
        client={clients.find((x) => x.id === values.clientId)}
        taskTypes={taskTypes}
        createdAt={createdAt}
        calculatefinalPrice={calculatefinalPrice}
        mechanicName={
          values?.mechanicName.length > 0 ? values?.mechanicName : user.fullName
        }
      />
    ),
  });

  const isInvoiceAfterwards =
    (values.workItemStatus === WorkItemStatus.Closed ||
      values.workItemStatus === WorkItemStatus.Evaluated ||
      values.workItemStatus === WorkItemStatus.Completed) &&
    !(values.orderId > 0);

  useEffect(() => {
    workItemUpdate(
      <WorkItemPDF
        workItem={values}
        client={clients.find((x) => x.id === values.clientId)}
        taskTypes={taskTypes}
        createdAt={createdAt}
        calculatefinalPrice={calculatefinalPrice}
        mechanicName={
          values?.mechanicName.length > 0 ? values?.mechanicName : user.fullName
        }
      />
    );
  }, [values, clients]);

  return (
    <Grid container justifyContent="center" spacing={1} pt={5}>
      <Grid item xs={6} md={6}>
        <strong>Nettó összeg:</strong>
      </Grid>
      <Grid item xs={6} md={3} textAlign="right">
        {formatCurrency(netPrice)}
      </Grid>
      <Grid item xs={6} md={6}>
        <strong> Bruttó összeg:</strong>
      </Grid>
      <Grid item xs={6} md={3} textAlign="right">
        {formatCurrency(grossPrice)}
      </Grid>
      <Grid item xs={8} md={6} sx={{ borderBottom: '2px solid' }}>
        <strong> Ebből kedvezmény:</strong>
      </Grid>
      <Grid
        item
        xs={4}
        md={3}
        textAlign="right"
        sx={{ borderBottom: '2px solid' }}
      >
        {formatCurrency(discount * -1)}
      </Grid>
      <Grid item md={6}>
        <strong> Bruttó fizetendő végösszeg:</strong>
      </Grid>
      <Grid item md={3} textAlign="right">
        {formatCurrency(payPrice)}
      </Grid>
      {(values.roleName === 'Mechanic' || isInvoiceAfterwards) && (
        <>
          <Grid item xs={12} pt={2} pb={2} textAlign={'center'}>
            <Autocomplete
              disablePortal
              id="companyId"
              disabled={readonly && !isInvoiceAfterwards}
              value={companyId}
              onChange={(event, value) => {
                dispatch(setCompanyId(value));
                setFieldValue('companyId', value);
              }}
              getOptionLabel={(option) =>
                clients.find((g) => g.id === option)?.companyName ?? ''
              }
              options={clients
                ?.filter((x) => x.clientType === ClientTypes.Distributor)
                ?.map((g) => g.id)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{ maxWidth: '300px' }}
                  required
                  label="Számlakibocsátó"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} pt={2} textAlign={'center'}>
            <Autocomplete
              disablePortal
              id="paymentType"
              disabled={readonly && !isInvoiceAfterwards}
              value={paymentType}
              onChange={(event, value) => {
                dispatch(setPaymentType(value));
                setFieldValue('paymentType', value);
              }}
              options={paymentTypes?.map((g) => g)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  style={{ maxWidth: '300px' }}
                  label="Fizetési mód"
                />
              )}
            />
          </Grid>
        </>
      )}
      <Grid item container xs={12} justifyContent="center" spacing={2}>
        {values.roleName === 'Mechanic' && (
          <Grid item>
            <Button
              onClick={() => setInvoiceDialogOpen(true)}
              disabled={
                readonly ||
                values.roleName !== 'Mechanic' ||
                isSubmitting ||
                !companyId ||
                !paymentType
              }
              variant="contained"
            >
              Számla kiállítása
            </Button>
          </Grid>
        )}
        {(values.workItemStatus === WorkItemStatus.Closed ||
          values.workItemStatus === WorkItemStatus.Evaluated ||
          values.workItemStatus === WorkItemStatus.Completed) &&
          hasNoInvoice && (
            <Grid item>
              <Button
                onClick={() => setInvoiceDialogOpen(true)}
                variant="contained"
              >
                Számla kiállítása utólag
              </Button>
            </Grid>
          )}
        {(values.roleName === 'Mechanic' || isInvoiceAfterwards) && (
          <Grid item>
            <Button
              onClick={() => setInvoiceAfterwardsDialog(true)}
              disabled={
                (readonly && !isInvoiceAfterwards) ||
                isSubmitting ||
                !companyId ||
                !paymentType ||
                paymentType === 'Bankkártya' ||
                paymentType === 'Átutalás'
              }
              variant="contained"
            >
              Zárás számla nélkül
            </Button>
          </Grid>
        )}
      </Grid>
      <InvoiceInfoDialog
        onSubmit={(invoiceInfo: any) => {
          if (
            values.workItemStatus === WorkItemStatus.Closed ||
            values.workItemStatus === WorkItemStatus.Evaluated ||
            values.workItemStatus === WorkItemStatus.Completed
          ) {
            let value = orderInstance.blob;

            const reader = new FileReader();
            reader.readAsDataURL(value);
            reader.onloadend = () => {
              const orderPdf = reader.result?.toString().split(',')[1];

              let value = workItemInstance.blob;

              const wReader = new FileReader();
              wReader.readAsDataURL(value);
              wReader.onloadend = () => {
                const workItemPdf = wReader.result?.toString().split(',')[1];
                addInvoice(values.id, invoiceInfo, orderPdf, workItemPdf).then(
                  () => {
                    setInvoiceDialogOpen(false);
                  }
                );
              };
            };
          } else {
            let value = orderInstance.blob;

            const reader = new FileReader();
            reader.readAsDataURL(value);
            reader.onloadend = () => {
              const orderPdf = reader.result?.toString().split(',')[1];

              let value = workItemInstance.blob;

              const wReader = new FileReader();
              wReader.readAsDataURL(value);
              wReader.onloadend = () => {
                const workItemPdf = wReader.result?.toString().split(',')[1];
                onCompleteWithInvoice(
                  values,
                  setIsSubmitting,
                  invoiceInfo,
                  orderPdf,
                  workItemPdf
                ).then(() => {
                  setInvoiceDialogOpen(false);
                });
              };
            };
          }
        }}
        open={invoiceInfoDialogOpen}
        setOpen={setInvoiceDialogOpen}
        clients={clients}
        workItemClient={values.clientId}
        invoiceNumberPrefixes={invoiceNumberPrefixes}
        onClientAdded={onClientAdded}
        documentBlocks={documentBlocks}
      />
      <Dialog
        open={inoviceAfterwardsDialog}
        onClose={() => setInvoiceAfterwardsDialog(false)}
      >
        <DialogTitle>Számlázás</DialogTitle>
        <DialogContent>
          <DialogContentText>Utólag számlázandó?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              let value = orderInstance.blob;

              const reader = new FileReader();
              reader.readAsDataURL(value);
              reader.onloadend = () => {
                const orderPdf = reader.result?.toString().split(',')[1];

                let value = workItemInstance.blob;

                const wReader = new FileReader();
                wReader.readAsDataURL(value);
                wReader.onloadend = () => {
                  const workItemPdf = wReader.result?.toString().split(',')[1];
                  onCompleteWithoutInvoice(
                    {
                      ...values,
                      invoiceAfterwards: true,
                      completeWorkFlow: isInvoiceAfterwards ? false : true,
                    },
                    setIsSubmitting,
                    paymentType,
                    companyId,
                    orderPdf,
                    workItemPdf
                  );
                };
              };
            }}
            variant="contained"
            color="primary"
          >
            Igen
          </Button>
          <Button
            onClick={() => {
              let value = orderInstance.blob;

              const reader = new FileReader();
              reader.readAsDataURL(value);
              reader.onloadend = () => {
                const orderPdf = reader.result?.toString().split(',')[1];

                let value = workItemInstance.blob;

                const wReader = new FileReader();
                wReader.readAsDataURL(value);
                wReader.onloadend = () => {
                  const workItemPdf = wReader.result?.toString().split(',')[1];
                  onCompleteWithoutInvoice(
                    {
                      ...values,
                      invoiceAfterwards: false,
                      completeWorkFlow: isInvoiceAfterwards ? false : true,
                    },
                    setIsSubmitting,
                    paymentType,
                    companyId,
                    orderPdf,
                    workItemPdf
                  );
                };
              };
            }}
            variant="contained"
            color="primary"
          >
            Nem
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default CompleteWorkItem;
