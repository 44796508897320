import { Configuration, PopupRequest } from '@azure/msal-browser';

export const msalConfig: Configuration = {
    auth: {
        clientId: '728ea139-e0a7-4628-be55-059b49a7e41e',
        authority:
            'https://login.microsoftonline.com/b00f09fb-461d-4f31-b7d9-55ded6dead85',
        redirectUri: '/',
        postLogoutRedirectUri: '/',
    },
    cache: {
        cacheLocation: 'localStorage',
    },
};

export const loginRequest: PopupRequest = {
    scopes: ['api://csi.jacana.api/read_write'],
};